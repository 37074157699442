$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;

#form{
    .form-wrapper{
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom: 230px;

        input{
            width: 100%;
            padding: 20px 15px;
            background: #ececec;
            border: none;
            border-radius: 5px;

            &:focus{
                border: none;
                box-shadow: none;
                outline: none;
            }

            &:focus-visible{
                border: none;
                box-shadow: none;
                outline: none;
            }
        }

        .form-row-custom{
            margin-bottom: 30px;
        }

        textarea{
            padding: 10px 15px ;
            width: 100%;
            background: #ececec;
            border: none;

            &:focus{
                border: none;
                box-shadow: none;
                outline: none;
            }

            &:focus-visible{
                border: none;
                box-shadow: none;
                outline: none;
            }
        }

        .submit-btn{
            background: #111;
            color: white;
            padding: 15px;
            width: 120px;
            border-radius: 30px;
            border: none;
            box-shadow: none;
            margin-left: auto;

            &:hover{
                background: #da2f46
            }
        }
    }
}

@media screen and (max-width: $medium){
    #form .form-wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }
}