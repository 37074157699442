$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


.button-black{
    .btn-main{
        width: 150px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: auto;
        position: relative;
    
        &::before{
            content: '';
            width: 48px;
            height: 48px;
            position: absolute;
            background-color: rgba(0,0,0,0.05);
            border-radius: 50%;
            z-index: -1;
            left: -15%;
            transition: all .5s ease-in-out;
        }
    
        
        &:hover{
    
            &::before{
                content: '';
                background-color: rgba(0,0,0,0.02);
                transition: all .3s ease-in-out;
                transform: scale(1.5);
            }
            .btn-inner:hover {
                background-size: 100% 100%;
              }
    
              .right-dash{
               
                margin-left: 25px;
                transition: all .3s ease-in-out;
                transform: rotate(90deg);
            }
        
        }
        .btn-inner {
            background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)) left no-repeat, rgba(0,0,0,0.8);
            background-size: 0% 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: .5s ease-in-out;
            font-size: 12px;
            line-height: 20px;
            font-weight: 700!important;
            letter-spacing: 3px;
            text-transform: uppercase;
          }
          
          
          .right-dash{
              width: 12px;
              height: 2px;
              background-color: rgba(0,0,0, 0.5);
              margin-left: 15px;
              transition: all .5s ease-in-out;
              position: relative;
    
              &::before{
                  content: '';
                  position: absolute;
                  transform: translate(-50%, -50%);
                  left: 50%;
                  top: 50%;
                  width: 2px;
                  height: 12px;
                  background-color: rgba(0,0,0, 0.5);
              }
          }
    }
    
    
}

@media screen and (max-width: $verySmall) {
    #HomeWork .homework-inner__wrapper .btn-wrapper{
        padding-top: 30px;
    }
}