$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;

.about-cont{
    padding-top: 230px;
    padding-bottom: 80px;
}

@media screen and (max-width: $verySmall) {
    .about-cont{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}


@media screen and (min-width: $verySmall) and (max-width: $small){
    .about-cont{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}


@media screen and (min-width: $small) and (max-width: $medium){
    .about-cont{
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    
}