$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


.ih-banner{
    .ih-banner__wrapper{
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        opacity: 0;

        &::before{
            content: '';
            display: block;
            width: 0%;
            height: 100%;
            position: absolute;
            top: 0%;
            right: 0%;
            background-color: #fff;
        }
        
        .img{
            // background: url('../images/some.webp');
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            img{
                width: 100%;
                top: 0%;
                left: 0%;
                position: absolute;
            }

            
        }   
    }
}

@media screen and (max-width: $verySmall){
    .ih-banner .ih-banner__wrapper{
        height: 50vh;
    }
}