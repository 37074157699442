$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


.about-inner{
   
    .about-inner__wrapper{
        padding-left: 40px;
        padding-right: 40px;

        .ai-para{
            position: relative;
            width: 50%;
            padding-bottom: 150px;
        }

        .ai-para-title{
            display: flex;
            align-items: center;
            position: relative;
           
            h4{
                font-weight: 700;
                font-size: 30px;
                padding-left: 40px;
                position: relative;
                margin-bottom: 25px;
                padding-bottom: 25px;
                color: #000;

                &::before{
                    content: "";
                    position: absolute;
                    top: 30%;
                    transform: translateY(-50%);
                    left: 0px;
                    display: flex;
                    width: 25px;
                    height: 3px;
                    background-color: #111;
                    margin-right: 100px;
                }
            }
        }

        .ai-para-content{
            padding-left: 40px;
            p{
                font-size: 20px;
                line-height: 36px;  
                color: #000;
            }
        }
    }
}

@media screen and (max-width: $verySmall) {
    .about-inner .about-inner__wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    .about-inner .about-inner__wrapper .ai-para{
        width: 100%;
        padding-bottom: 100px;
    }

    .about-inner .about-inner__wrapper .ai-para-title h4{
        padding-bottom: 25px;
    }

    .about-inner .about-inner__wrapper .ai-para-title h4::before{
        top: 30%;
    }

    .about-inner .about-inner__wrapper .ai-para-content p{
        font-size: 16px;
        line-height: 26px;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    .about-inner .about-inner__wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    .about-inner .about-inner__wrapper .ai-para-content p{
        font-size: 16px;
        line-height: 26px;
    }
    
    .about-inner .about-inner__wrapper .ai-para-title h4{
        padding-bottom: 25px;
    }

    .about-inner .about-inner__wrapper .ai-para{
        width: 100%;
        padding-bottom: 100px;
    }
    .about-inner .about-inner__wrapper .ai-para-title h4::before{
        top: 30%;
    }
}


@media screen and (min-width: $small) and (max-width: $medium){
    .about-inner .about-inner__wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    .about-inner .about-inner__wrapper .ai-para{
        width: 100%;
        padding-bottom: 100px;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    .about-inner .about-inner__wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    .about-inner .about-inner__wrapper .ai-para{
        
        padding-bottom: 100px;
    }
}