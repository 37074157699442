$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;

.padding-left{
    padding-left: 30px;
}

.bg-pos{
    background-position: 0 100%!important;
}
#navbar{
    a{
        text-decoration: none;
    }
    .nav-toggler__wrapper{
        position: relative;
        .nav-toggler{   
            height: auto;
            position: fixed;
            display: flex;
            right: 0%;
            top: 0px;
            margin-top: 33.5px;
            margin-right: 30px;
            z-index: 1000;
            
            span{
                z-index: 100!important;
                display: flex;
                flex-direction: column;
                width: 30px;
                height: 1px;
                // background-color: #fff;
                margin-top: 3.5px;
                transform-origin: center center;          
            }

            .span-cross{
                width: 30px;
                height: 2px;
                background-color: black!important;
                position: absolute;
                opacity: 0;
                top: 50%;
                transform: translate(0%, -50%);
            }
        }
    }
    
    #nav-overlay{
        position: fixed;
        width: 0%;
        height: 100vh;
        background: #fff;
        z-index: 5;
        top: 0;
        right: 0;
        overflow: hidden;

        .nav-inner__wrapper{
            width: 100%;
            height: 100vh;
            padding-left: 80px;
            padding-right: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .nav-inner__caption{
                font-size: 14px;
                font-weight: 600;
                color: rgba(0,0,0,0.5);
                padding-bottom: 20px;
            }

            .menu{
                .menu-inner{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-between;
    
                    .menu-item{
                        width: max-content;
                        opacity: 1;
                        
                        .first-link{
                            display: flex;                          
                        }

                        &:hover{
                            
                            transition: all 0.3s;

                            &::before{
                                content: '';
                                background-color: rgba(0,0,0,0.02);
                                transition: all .3s ease-in-out;
                                transform: scale(1.5);
                            }
                        }
                        h1{
                            transition: all 275ms ease;
                            background: linear-gradient(to left, #111, #111 50%, #eee 50%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-size: 200% 100%;
                            background-position: -100%;
                            font-size: 75px;
                            line-height: 1.2;
                            font-weight: 700;  
                            
                            
                            }

                            
                    }
                }

                .menu-address{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    .menu-address__wrapper{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        .email-p{
                            a{
                                text-decoration: underline!important;
                                color: #da2f46;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 1.8;
                            }
                        }

                        .tel-p{
                            p{
                                color: #da2f46;
                                font-size: 14px;
                                font-weight: 600;
                                text-decoration: underline;
                                line-height: 1.8;
                            }
                        }

                        .nav-address{
                            padding-top: 15px;
                            p{
                                font-size: 14px;
                                font-weight: 600;
                                color: rgba(0,0,0,0.5)
                            }
                        }
                    }
                }
            }
            
        }
    }
    .frame{
        position: fixed;
        width: 100%;
        height: 50px;
        padding-left: 30px;
        padding-right: 30px;
        z-index: 5;

        .frame-inner{
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: row;
            position: relative;
            justify-content: space-between;

            .logo{
                color: #fff;
                width: 150px;
                padding-top: 30px;
                
                svg {
                    
                    path{
                        fill: #fff;
                    }
                }
            }

            .header-links{
                padding-top: 30px;
                padding-right: 50px;
                color: #fff;
                font-weight: 700;

                a{
                    p{
                        color: #fff;
                    }
                }
            }
        }
    }
}

.has-scrolled{
    background: #111!important;
}

@media screen and (max-width: $verySmall){
    #navbar .frame .frame-inner .logo{
        width: 90px;
    }
    .menu-address .menu-address__wrapper{
        display: none;
    }
    #navbar #nav-overlay .nav-inner__wrapper .menu .menu-inner .menu-item h1{
        font-size: 62px;
    }

    #navbar #nav-overlay .nav-inner__wrapper{
        padding-left: 40px;
        padding-right: 40px;
    }
}


@media screen and (min-width: $verySmall) and (max-width: $small){

    #navbar .frame .frame-inner .logo{
        width: 100px;
    }
    .menu-address .menu-address__wrapper{
        display: none;
    }
    #navbar #nav-overlay .nav-inner__wrapper .menu .menu-inner .menu-item h1{
        font-size: 62px;
    }

    #navbar #nav-overlay .nav-inner__wrapper{
        padding-left: 40px;
        padding-right: 40px;
    }
}


@media screen and (min-width: $small) and (max-width: $medium){
    #navbar .frame .frame-inner .logo{
        width: 110px;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    #navbar .frame .frame-inner .logo{
        width: 130px;
    }
}