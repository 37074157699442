$verySmall: 480px;
$small: 768px;
$medium: 1024px;
$large: 1200px;
$extraLarge: 1912px;


#our-work{
    .homework-inner__work{
        position: relative;
        padding-top: 0px;
        padding-bottom: 100px;

        .hw-wrapper{
            width: 100%;
            height: 750px;
            position: relative;
            display: block;
            perspective: 1000;

            .proj-title{
                position: absolute;
                right: 8%;
                bottom: 8%;
                display: flex;
                flex-direction: column;
                text-align: right;
                pointer-events: none;
                
                h3{
                    color: #1F1E1D;
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 65px;
                    pointer-events: none;
                }

                p{
                    font-size: 16px;
                    color: #1F1E1D;
                    pointer-events: none;
                }
            }

           
            .hw-wrapper__img{
                position: relative;
                width: 90%;
                height: 100%;
                overflow: hidden;
                transition: all 0.4s ease;
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                bottom: 0px;
                
                &:hover{
                    transition: all 0.4s ease;
                    clip-path: polygon(5% 5%,100% 0%,100% 100%,5% 95%);

                    img{
                        transform: scale(1.05
                        );
                        transition: all 0.8s ease-in;
                        transition-delay: .1s;
                    }
                }
                img{
                    position: absolute;
                    transition: all 0.4s ease-out;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 101%;
                    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
                   }
            }

            
        }
    }
}

@media screen and (max-width: $verySmall){

    #our-work .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 550px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    
    #our-work .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 100%;
    }

    #our-work .homework-inner__work{
        padding-bottom: 0px;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    #our-work .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 750px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    
    #our-work .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 100%;
    }

    #our-work .homework-inner__work{
        padding-bottom: 0px;
    }

    #our-work .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 750px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    
}

@media screen and (min-width: $small) and (max-width: $medium){
    #our-work .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 750px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    
    #our-work .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 90%;
    }

    #our-work .homework-inner__work{
        padding-bottom: 0px;
    }

    #our-work .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 550px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

}

@media screen and (min-width: $medium) and (max-width: $large){
    #our-work .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 750px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    
    #our-work .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 90%;
    }

    #our-work .homework-inner__work{
        padding-bottom: 0px;
    }

    #our-work .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 550px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

}