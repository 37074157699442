$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


#single-project{
    background-color: #f7f7f7;

    .single-proj-img{
        width: 100%;
        height: 700px;
        overflow: hidden;
        img{
            width: 100%;
            
        }
    }
    .overview{
        padding: 230px 40px 230px 40px;

        h1{
            font-size: 38px;
            line-height: 48px;
            font-weight: 700;
            color: #000;
        }

        p{
            
            color: #000;
            padding-top: 30px;
            font-size: 20px;
            line-height: 36px;
        
        }

        ul{
            padding-top: 30px;
            position: relative;
            li{
                list-style: none;
                font-size: 20px;
                line-height: 36px;
                position: relative;
                display: flex;
                color: #000;
                padding-left: 50px;
                padding-top: 0px;
                padding-bottom: 10px;

                &::before{
                    content: "";
                    position: absolute;
                    top: 17px;
                    transform: translateY(-50%);
                    left: 0px;
                    display: flex;
                    width: 25px;
                    height: 3px;
                    background-color: #da2f46;
                    margin-right: 100px;
                }
            
            }
        }
    }

    .challenge{
        padding: 230px 40px 230px 40px;

        h1{
            font-size: 38px;
            line-height: 48px;
            font-weight: 700;
            color: #000;
        }

        p{
            
            color: #000;
            padding-top: 30px;
            font-size: 20px;
            line-height: 36px;
        
        }

    }

    .right{
        margin-top: 100px;
    }

    .single-proj-imgs{
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 230px;
        .img-wrapper{
            width: 90%;

            

            img{
                width: 100%;
                height: 100%;
            }
        }
    }

    .next-project-btn-wrapper{
        padding: 230px 0px;
    }
}

@media screen and (max-width: $verySmall){

    #single-project .single-proj-img{
        height: 200px;
    }

    #single-project .overview{
        padding: 60px 10px 60px 10px;
    }

    #single-project .overview p{
        font-size: 16px;
        line-height: 26px;
    }

    #single-project .overview ul {
        padding-left: 0px;
    }

    #single-project .overview ul li{
        &::before{
            top: 14px;
        }
        font-size: 16px;
        line-height: 26px;
    }

    #single-project .challenge{
        padding: 60px 10px 60px 10px;
    }

    #single-project .challenge p{
        font-size: 16px;
        line-height: 26px;
    }

    #single-project .img-wrapper{
        margin: 30px 0px;
    }

    #single-project .next-project-btn-wrapper{
        padding: 50px 0px 100px 0px
    }

}

@media screen and (min-width: $verySmall) and (max-width: $small){
    #single-project .single-proj-img{
        height: 200px;
    }
}

@media screen and (min-width: $small) and (max-width: $medium){
    #single-project .single-proj-img{
        height: 300px;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $medium){
    #single-project .overview{
        padding: 60px 10px 60px 10px;
    }

    
    #single-project .overview p{
        font-size: 16px;
        line-height: 26px;
    }

    #single-project .overview ul li{

        &::before{
            top: 14px;
        }
        font-size: 16px;
        line-height: 26px;
    }

    #single-project .challenge{
        padding: 60px 10px 60px 10px;
    }

    #single-project .challenge p{
        font-size: 16px;
        line-height: 26px;
    }

    #single-project .img-wrapper{
        margin: 30px 0px;
    }

    #single-project .next-project-btn-wrapper{
        padding: 50px 0px 100px 0px
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    #single-project .overview{
        padding: 100px 10px 100px 10px;
    }

    #single-project .single-proj-img{
        height: 450px;
    }

    #single-project .overview p{
        font-size: 20px;
        line-height: 36px;
    }

    #single-project .overview ul li{

        &::before{
            top: 14px;
        }
        font-size: 20px;
        line-height: 36px;
    }

    #single-project .challenge{
        padding: 100px 10px 100px 10px;
    }

    #single-project .challenge p{
        font-size: 20px;
        line-height: 36px;
    }

    #single-project .img-wrapper{
        margin: 30px 0px;
    }

    #single-project .next-project-btn-wrapper{
        padding: 50px 0px 100px 0px
    }
}