$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;



#innerHero{
    position: relative;
    width: 100%;
    overflow-x: hidden;
    .innerHero__wrapper{
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        height: 80vh;

        .innerHero__caption{
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 30px !important;
            letter-spacing: 2px;
            color: #da2f46;
        }

        .innerHero__title{
            
            max-width: 90%;
            font-size: 62px;
            line-height: 78px;
            font-weight: 700;
        }
    }


    .ih-main__wrapper{
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 230px;
     
        .ih-para-one{
            width: 80%;
            display: flex;
            padding-bottom: 100px;
            
            p{
                font-size: 32px;
                font-weight: 600;
                line-height: 50px;
                position: relative;
                color: #000;
                
                &::before{
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: 0px;
                    display: flex;
                    width: 30px;
                    height: 4px;
                    background-color: #111;
                    margin-right: 100px;
                    display: none;
                }
            }
        }
    }

    
    .ih-services{
        .ih-services__wrapper{
            padding-top: 230px;
            padding-bottom: 120px;
            padding-left: 40px;
            padding-right: 40px;

            .ih-services__title{
                width: 70%;
                padding-bottom: 100px;
                .line{
                    height: 78px;
                    overflow: hidden;
                }
                h2{
                    font-size: 62px;
                    line-height: 78px;
                    font-weight: 700;
                    color: #000;
                }
            }

            .ih-services-list{
                width: 100%;

                .ih-list__wrapper{
                    width: 60%;
                    
                    display: flex;
                    justify-content: space-between;

                    ul{
                        margin: 0!important;
                        padding: 0!important;

                        li{
                            font-size: 20px;
                            line-height: 36px;
                            font-weight: 600;
                            display: flex;
                            position: relative;
                            padding-left: 50px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            color: #000;

                            &::before{
                                content: '';
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 0px;
                                display: flex;
                                width: 25px;
                                height: 3px;
                                background-color: #da2f46;
                                margin-right: 100px;
                            }
                        }
                    }
                }
                
            }

            
        }
    }
    
}

@media screen and (max-width: $verySmall){
    #innerHero .ih-services .ih-services__wrapper{
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title{
        width: 100%;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title .line{
        height: 48px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title{
        padding-bottom: 30px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper ul li::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        display: flex;
        width: 16px;
        height: 3px;
        background-color: #da2f46;
        margin-right: 30px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper ul li{
        padding-left: 30px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title .line h2{
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 0px;
        font-weight: 700;
        padding-bottom: 30px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper{
        width: 100%;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper ul li{
        font-size: 16px;
    }

    #innerHero .ih-main__wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
    }

    #innerHero .ih-main__wrapper .ih-para-one{
        width: 100%;
        padding-bottom: 60px;

        p{
            font-size: 26px;
            line-height: 38px;
        }
    }
}


@media screen and (min-width: $verySmall) and (max-width: $small){
    #innerHero .ih-services .ih-services__wrapper{
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title{
        width: 100%;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title .line{
        height: 48px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title{
        padding-bottom: 30px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper ul li::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        display: flex;
        width: 16px;
        height: 3px;
        background-color: #da2f46;
        margin-right: 30px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper ul li{
        padding-left: 30px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title .line h2{
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 0px;
        font-weight: 700;
        padding-bottom: 30px;
       
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper{
        width: 100%;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper ul li{
        font-size: 16px;
    }

    #innerHero .ih-main__wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
    }

    #innerHero .ih-main__wrapper .ih-para-one{
        width: 100%;
        padding-bottom: 60px;

        p{
            font-size: 26px;
            line-height: 38px;
        }
    }
}   


@media screen and (min-width: $small) and (max-width: $medium){
    #innerHero .ih-services .ih-services__wrapper{
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #innerHero .ih-services .ih-services__wrapper .ih-services__title{
        width: 100%;
    }

    // #innerHero .ih-services .ih-services__wrapper .ih-services__title h2{
    //     font-size: 28px;
    //     line-height: 38px;
    //     margin-bottom: 0px;
    //     font-weight: 700;
    //     padding-bottom: 30px;
    // }

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper{
        width: 80%;
    }

    

    #innerHero .ih-services .ih-services__wrapper .ih-services-list .ih-list__wrapper ul li{
        font-size: 16px;
    }

    #innerHero .ih-main__wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0px;
    }

    #innerHero .ih-main__wrapper .ih-para-one{
        width: 100%;

        p{
            font-size: 26px;
            line-height: 38px;
        }
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    #innerHero .ih-services .ih-services__wrapper{
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #innerHero .ih-main__wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 100px;
    }
}