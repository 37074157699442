$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;

#hww{
    .hww-wrapper{
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 230px;
        padding-bottom: 230px;

        .hww-title{

            padding-bottom: 100px;

            .line{
                height: 78px;
                overflow: hidden;
               
                h2{
                    font-size: 62px;
                    line-height: 78px;
                    font-weight: 700;
                    color: #000;
                    text-align: center;
                }
            }
            
        }
    }

    .icon{
        font-size: 50px;
        text-align: center;
        margin: 0 40px 80px;
    }

   
}

@media screen and (max-width: $verySmall){
    #hww .hww-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    #hww .hww-wrapper .hww-title h1{
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 0px;
        padding-bottom: 30px;
    }

    #hww .hww-wrapper .hww-title{
        padding-bottom: 60px;
    }

    #hww .icon{
        font-size: 40px;
        margin: 0 40px 40px
    }
    #hww .hww-wrapper .hww-title .line h2{
        font-size: 38px;
        line-height: 48px;
    }

    #hww .hww-wrapper .hww-title .line {
        height: 48px;
    }
}


@media screen and (min-width: $verySmall) and (max-width: $small){
    #hww .hww-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    #hww .hww-wrapper .hww-title h1{
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 0px;
        padding-bottom: 30px;
    }

    #hww .hww-wrapper .hww-title{
        padding-bottom: 60px;
    }

    #hww .icon{
        font-size: 40px;
        margin: 0 40px 40px
    }
    #hww .hww-wrapper .hww-title .line h2{
        font-size: 38px;
        line-height: 48px;
    }

    #hww .hww-wrapper .hww-title .line {
        height: 48px;
    }
}


@media screen and (min-width: $small) and (max-width: $medium){
    #hww .hww-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    #hww .hww-wrapper .hww-title h1{
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 0px;
        padding-bottom: 30px;
    }

    #hww .icon{
        font-size: 60px;
        margin: 0 40px 40px
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    
}