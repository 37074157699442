$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;

.hww-banner{
    background-color: #da2f46;

    .btn-position{
        margin-left: 30px;
        margin-top: 40px
    }

    .hww-inner-wrapper{
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 230px;
        padding-bottom: 230px;

        .hww-banner-content{
            p{
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 30px !important;
                letter-spacing: 2px;
                color: #fff;
            }
            
            h2{
                padding-left: 30px;
                font-size: 62px;
                line-height: 78px;
                font-weight: 700;
                color: white;
                max-width: 60%;
            }
        }
    }
}

@media screen and (max-width: $verySmall){
    .hww-banner .hww-inner-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content h2{
        max-width: 100%;
        font-size: 28px;
        line-height: 38px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content p{
        margin-bottom: 15px!important;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    .hww-banner .hww-inner-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content h2{
        max-width: 100%;
        font-size: 28px;
        line-height: 38px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content p{
        margin-bottom: 15px!important;
    }
}


@media screen and (min-width: $small) and (max-width: $medium){
    .hww-banner .hww-inner-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content h2{
        max-width: 100%;
        font-size: 32px;
        line-height: 48px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content p{
        margin-bottom: 15px!important;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    .hww-banner .hww-inner-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content h2{
        max-width: 100%;
        font-size: 40px;
        line-height: 60px;
    }

    .hww-banner .hww-inner-wrapper .hww-banner-content p{
        margin-bottom: 15px!important;
    }
}