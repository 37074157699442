$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


.HomeServices-banner{
    width: 100%;
    height: 100vh;
    background: #da2f46;
    position: relative;
    display: flex;

    .HomeServices-inner{
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        p{
            color: white;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 30px!important;
            letter-spacing: 2px;
        }

        h2{
            padding-left: 40px;
            font-size: 36px;
            color: #fff;
            font-weight: 700;
            line-height: 70px;
        }
    }
    

}


@media screen and (max-width: $verySmall){
    #HomeServices .HomeServices-banner{
        height: 100vh;
    }
    #HomeServices .HomeServices-banner .HomeServices-inner h2{
        font-size: 26px;
        line-height: 38px;
    }

    .HomeServices-banner .HomeServices-inner{
        padding-left: 0px;
        padding-right: 0px;
    }

    .HomeServices-banner .HomeServices-inner h2 {
        
        font-size: 22px;
        color: #fff;
        font-weight: 700;
        line-height: 32px;
    }

    #HomeServices .HomeServices-banner .HomeServices-inner{
        padding-left: 10px;
        padding-right: 10px;
    }
    .HomeServices-banner .HomeServices-inner h2{
        padding-left: 0px;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    #HomeServices .HomeServices-banner{
        height: 100vh;
    }

    .HomeServices-banner .HomeServices-inner{
        padding-left: 10px;
        padding-right: 10px;
    }

    .HomeServices-banner .HomeServices-inner h2{
        font-size: 30px;
        line-height: 48px;
    }
    #HomeServices .HomeServices-banner .HomeServices-inner h2{
        font-size: 28px;
        line-height: 40px;
    }

    #HomeServices .HomeServices-banner .HomeServices-inner{
        padding-left: 10px;
        padding-right: 10px;
    }
}