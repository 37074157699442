$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


#HomeWork{
    .homework-inner__wrapper{
        padding: 230px 40px 230px 40px;

        .btn-wrapper{
            padding-top: 230px;
            text-align: center;
        }

        .homework-header{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p{
                font-size: 20px;
                line-height: 36px;
            }

            .line{
                height: 78px;
                overflow: hidden;
                h1{
                    font-size: 62px;
                    color: #1F1E1D;
                    font-weight: 700;
                    line-height: 78px;
                }
            }

            

            button{
                background: black;
                border: 0px;
                padding-top: 20px;
                padding-bottom: 20px;
                border-radius: 30px;
                color: white;
                width: 170px;
            }
        }

        .homework-inner__work{
            position: relative;
            padding-top: 100px;
            padding-bottom: 25px;

            .hw-wrapper{
                width: 100%;
                height: 750px;
                position: relative;
                display: block;
                perspective: 1000;

                .proj-title{
                    position: absolute;
                    right: 5%;
                    bottom: 8%;
                    display: flex;
                    flex-direction: column;
                    text-align: right;
                    pointer-events: none;
                    
                    h3{
                        color: #1F1E1D;
                        font-size: 36px;
                        font-weight: 700;
                        line-height: 65px;
                        pointer-events: none;
                    }
    
                    p{
                        font-size: 14px;
                        color: #1F1E1D;
                        pointer-events: none;
                    }
                }

               
                .hw-wrapper__img{
                    position: relative;
                    width: 90%;
                    height: 100%;
                    overflow: hidden;
                    transition: all 0.4s ease;
                    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    background-color: #ececec;
                    
                    &:hover{
                        transition: all 0.4s ease;
                        clip-path: polygon(5% 5%,100% 0%,100% 100%,5% 95%);

                        img{
                            transform: scale(1.05
                            );
                            transition: all 0.8s ease-in;
                            transition-delay: .1s;
                        }
                    }
                    img{
                        position: absolute;
                        transition: all 0.4s ease-out;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 101%;
                    }
                }

                
            }
            
        }
    }
}


@media screen and (max-width: $verySmall) {
    #HomeWork .homework-inner__wrapper{
        padding: 60px 10px 60px 10px
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 550px;
    }

    

    #HomeWork .homework-inner__wrapper .homework-header .line{
        height: 48px;
    }

    #HomeWork .homework-inner__wrapper .homework-header .line h1{
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 0px;
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper .proj-title h3{
        font-size: 26px;
        line-height: 38px;
    }

    #HomeWork .homework-inner__wrapper .homework-header p{
        font-size: 16px;
        line-height: 26px;
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work{
       
        padding-top: 0px;
        padding-bottom: 0px;
    }


    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 100%;
    }
    

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

//Small Tablets

@media screen and (min-width: $verySmall) and (max-width: $small){
    #HomeWork .homework-inner__wrapper{
        padding: 60px 10px 60px 10px
    }

    #HomeWork .homework-inner__wrapper .btn-wrapper{
        padding-top: 80px;
        padding-bottom: 60px;
    }

    
    #HomeWork .homework-inner__wrapper .homework-header .line{
        height: 48px;
    }

    #HomeWork .homework-inner__wrapper .homework-header .line h1{
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 0px;
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper .proj-title h3{
        font-size: 28px;
        line-height: 40px;
    }

    #HomeWork .homework-inner__wrapper .homework-header p{
        font-size: 16px;
        line-height: 26px;
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work{
       
        padding-top: 0px;
        padding-bottom: 0px;
    }


    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 100%;
    }
    

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: $small) and (max-width: $medium){
    #HomeWork .homework-inner__wrapper{
        padding: 100px 10px 100px 10px
    }

    

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 550px;
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work{
        padding-top: 0px;
        padding-bottom: 0px;
    }


    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 90%;
    }
    

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    #HomeWork .homework-inner__wrapper{
        padding: 100px 10px 100px 10px
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper{
        width: 100%;
        height: 550px;
    }

    #HomeWork .homework-inner__wrapper .homework-inner__work{
        padding-top: 34px;
        padding-bottom: 0px;
    }


    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper .hw-wrapper__img{
        width: 90%;
    }
    

    #HomeWork .homework-inner__wrapper .homework-inner__work .hw-wrapper{
        margin-top: 30px;
        margin-bottom: 30px;
    }

}