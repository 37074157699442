@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  margin: 0px;
  padding: 0px;
}

html{
  width: 100%;
  overflow-x: hidden;
}

body{
  font-family: 'Raleway', sans-serif!important;
  margin: 0px;
  padding: 0px;
  /* height: 100vh; */
  width: 100%;
   will-change: transform; 
}



p{
  margin-bottom: 0px!important;
}

.scrollbar-track-x{
  display: none!important;
}

#root{
  width: 100%;
  height: 100vh;
  overflow: hidden;

}