$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;

#single-blog{

    .blogs-wrapper{
        padding-top: 230px;
        padding-left: 40px;
        padding-right: 40px;

        .para-one-title{
            padding-bottom: 30px;
            h2{
                font-size: 38px;
                line-height: 48px;
                font-weight: 700;
                color: #000;
            }
        }

        .para-one-content{
            font-size: 20px;
            line-height: 36px;
            color: #000;
            padding-bottom: 100px;

            span{
                font-size: 20px;
                line-height: 36px;
                color: #000;
            }

            p{
                font-size: 20px;
                line-height: 36px;
                color: #000;
            }

            li{
                list-style: none;
            }

            .parent-ul{
                margin: 0px!important;
                padding-top: 30px!important;
            }

            ul{
                
                li{
                    font-size: 20px;
                    line-height: 36px;
                    position: relative;
                    display: flex;
                    color: #000;
                    padding-left: 50px;
                    padding-top: 0px;
                    padding-bottom: 10px;
                    // list-style:disc ;

                    &::before{
                        content: "";
                        position: absolute;
                        top: 17px;
                        transform: translateY(-50%);
                        left: 0px;
                        display: flex;
                        width: 25px;
                        height: 3px;
                        background-color: #da2f46;
                        margin-right: 100px;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: $verySmall){
    #single-blog .blogs-wrapper{
        padding: 60px 10px 60px 10px;
    }

    #single-blog .blogs-wrapper .para-one-content p{
        font-size: 16px;
        line-height: 26px;
    }

    #single-blog .blogs-wrapper .para-one-content ul li{
        font-size: 16px;
        line-height: 26px;
    }

    #single-blog .blogs-wrapper .para-one-content .parent-ul{
        padding-left: 0px;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $medium){
    #single-blog .blogs-wrapper{
        padding: 60px 10px 60px 10px;
    }

    #single-blog .blogs-wrapper .para-one-content p{
        font-size: 16px;
        line-height: 26px;
    }

    #single-blog .blogs-wrapper .para-one-content ul li{
        font-size: 16px;
        line-height: 26px;
    }

    #single-blog .blogs-wrapper .para-one-content .parent-ul{
        padding-left: 0px;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    #single-blog .blogs-wrapper{
        padding: 100px 10px 100px 10px;
    }

    #single-blog .blogs-wrapper .para-one-content p{
        font-size: 20px;
        line-height: 36px;
    }

    #single-blog .blogs-wrapper .para-one-content ul li{
        font-size: 20px;
        line-height: 36px;
    }

    #single-blog .blogs-wrapper .para-one-content .parent-ul{
        padding-left: 0px;
    }
}