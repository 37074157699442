$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


#page-head{
    position: relative;
    
    .plus3 {
        width: 100px;
        top: 50%;
        position: absolute;
        right: 0;
        z-index: -1;
        svg{
            width: 100%;

            rect{
                fill: rgba(0,0,0,0.1);
                stroke: 0px!important;
                
                stroke-miterlimit: 10;
                stroke-width: 0px;
            }
        }
    }

    .circle{
        position: absolute;
        top: -20%;
        left: 20%;
        width: 90%;
        z-index: -1;
        svg{
            circle{
                stroke: rgba(0,0,0,0.1);
                stroke-width: 1px;
                fill: none!important;
            }
        }

    }   
    
    .innerHero__wrapper{
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        height: 70vh;

        .innerHero__inner{
            display: flex;    
        }
    
        .innerHero__caption{
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 30px !important;
            margin-top: 50px;
            letter-spacing: 2px;
            color: #da2f46;
        }
    
        .innerHero__title{

            .line{
                height: 78px;
                overflow: hidden;

                h2{
                    padding-left: 40px;
                    max-width: 80%;
                    font-size: 62px;
                    line-height: 78px;
                    font-weight: 700;
                    color: #000;
                }
            }
        }
    }
}

@media screen and (max-width: $verySmall){
    #page-head .innerHero__wrapper .innerHero__title .line h2{
        font-size: 30px;
        line-height: 44px;
        margin-bottom: 0px;
        max-width: 100%;
        padding-left: 0px;
    }

    #page-head .innerHero__wrapper .innerHero__title .line{
        height: 44px;
    }

    #page-head .innerHero__wrapper .innerHero__caption{
        margin-bottom: 15px!important;
    }
    
    #page-head .innerHero__wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    #page-head .innerHero__wrapper{
        height: 50vh;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    #page-head .innerHero__wrapper .innerHero__title .line{
        height: 48px;
    }

    #page-head .innerHero__wrapper .innerHero__title .line h2{
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 0px;
        max-width: 100%;
        padding-left: 20px;
    }

    #page-head .innerHero__wrapper .innerHero__caption{
        margin-bottom: 15px!important;
    }
    
    #page-head .innerHero__wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    #page-head .innerHero__wrapper{
        height: 50vh;
    }
}
