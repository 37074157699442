$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


#process{
    background-color: #1F1E1D;
    position: relative;

    .plus3 {
        width: 50px;
        top: 20%;
        position: absolute;
        left: 40%;
        

        svg{
            width: 100%;

            rect{
                fill: rgba(255,255,255,1);
                stroke: 0px!important;
                
                stroke-miterlimit: 10;
                stroke-width: 0px;
            }
        }
    }

    .circle{
        position: absolute;
        top: 10%;
        left: -40%;
        width: 90%;

        svg{
            circle{
                stroke: rgba(255,255,255,0.2);
                stroke-width: 1px;
                fill: none;
            }
        }

    }   
    .process-wrapper{
        padding-left: 40px;
        padding-right: 40px;
        // max-height: 310vh;
        overflow: hidden;

        .the-height{
            max-height: 310vh;
        }

        .process-inner{
            position: relative;
            .process-caption{
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 30px !important;
                letter-spacing: 2px;
                color: #da2f46;
            }
            
            .process-inner__title{
                width: 100%;
                padding-top: 230px;
                padding-bottom: 0px;
            }
            .line{
                height: 78px;
                position: relative;
                overflow: hidden;
                width: 100%;

                h2{
                    position: absolute;
                    // padding-top: 230px;
                    font-size: 62px;
                    line-height: 78px;
                    font-weight: 700;
                    color: #fff;
                    padding-left: 30px;
                }
            }
            

            .process-card:last-child{
                padding-bottom: 100px!important;
            }

            .process-cards{
                padding-top: 230px;
            }

            .process-card{
                
                padding-left: 40px;
                padding-bottom: 150px;
                width: 80%;

                
                .process-card-title{
                    h3{
                        color: #fff;
                        margin: 0px!important;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 44px;
                        letter-spacing: 2px;
                        padding-bottom: 25px;
                    }
                }

                .pocess-card-content{
                    p{
                        font-size: 20px;
                        line-height: 36px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $verySmall){
    #process .process-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    #process .process-wrapper .process-inner .process-card{
        padding-bottom: 100px;
    }

    #process .process-wrapper .process-inner .line {
        height: 48px;
    }

    #process .process-wrapper .process-inner .line h2{
        font-size: 38px;
        line-height: 48px;
    }

    #process .process-wrapper .process-inner .process-inner__title{
        padding-top: 0px;
    }

    #process .process-wrapper .process-inner .process-cards{
        padding-top: 60px;
    }

    #process .process-wrapper .process-inner .process-card .pocess-card-content p{
        font-size: 16px;
        line-height: 26px;
    }

    #process .process-wrapper{
        height: auto;
        max-height: 500vh;
    }

    
}


@media screen and (min-width: $verySmall) and (max-width: $small){
    #process .process-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    #process .process-wrapper .process-inner .process-card{
        padding-bottom: 100px;
    }

    #process .process-wrapper .process-inner .line {
        height: 48px;
    }

    #process .process-wrapper .process-inner .line h2{
        font-size: 38px;
        line-height: 48px;
    }

    #process .process-wrapper .process-inner .process-inner__title{
        padding-top: 0px;
    }

    #process .process-wrapper .process-inner .process-cards{
        padding-top: 60px;
    }

    #process .process-wrapper .process-inner .process-card .pocess-card-content p{
        font-size: 16px;
        line-height: 26px;
    }
    #process .process-wrapper{
        height: auto;
        max-height: 500vh;
    }
    
}


@media screen and (min-width: $small) and (max-width: $medium){
    #process .process-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    #process .process-wrapper .process-inner .process-card{
        padding-bottom: 100px;
    }

    #process .process-wrapper .process-inner .process-inner__title{
        padding-top: 60px;
    }

    #process .process-wrapper .process-inner .process-cards{
        padding-top: 60px
    }
    #process .process-wrapper{
        height: auto;
        max-height: 500vh;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    #process .process-wrapper{
        height: auto;
        max-height: 500vh;
    }
}