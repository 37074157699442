$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$large-max: 1450px;
$extraLarge: 1912px;

#home-hero{
    background: #1F1E1D;
    height: 100vh;
    overflow: hidden;
    display: flex;
    position: relative;
    opacity: 0;
    .plus1{
        position: absolute;
        width: 75px;
        left: 45%;
        top: 50%;
        transform: translate(-50%, -50%);

        img{
            width: 100%;
        }
    }

    .plus2{
        position: absolute;
        width: 55px;
        left: 10%;
        top: 70%;
        transform: translate(-50%, -50%);

        img{
            width: 100%;
        }
    }

    .logion-ver{
        position: absolute;
        width: 1300px;
        left: 20%;
        top: 40%;
        z-index: 1;
        transform: translate(-50%, -50%);
        #Layer_1{
            transform: rotateZ(270deg)!important;
           path{
                fill: #272628;
                stroke: #272628;
           }
        }
    }

    .logion-circle{
        position: absolute;
        width: 80%;
        left: 20%;
        top: 10%;
        z-index: 1;
        transform: translate(-50%, -50%);
        svg{
            opacity: 0.1;
            .cls-1{
                fill: none!important
            }
           path{
                fill: #272628!important;  
           }
        }
    }

    @media screen and (max-width: $verySmall) {
        height: 100vh;
    }

    .container-custom{
        width: 85%;
        margin: auto;
    }
    

    .hero__inner-wrapper{
        width: 100%;
        height: 100%;
        margin: auto;
        // padding-top: 80px;
        // padding-bottom: 80px;
        position: relative;
        padding-left: 40px;
        padding-right: 40px;
        
        

        .hero__inner-content{
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;
            transform: translateY(-50%);
            // margin-left: auto;
            margin-left: auto;
            width: 100%;
            height: auto;
            text-align: right;
            overflow-x: hidden;
            overflow-y: hidden;

            .line{
                height: 210px;
                overflow: hidden;
                h1{
                    font-size: 180px;
                    line-height: 1.08;
                    color: rgba(255,255,255,0.9);
                    margin-bottom: 0!important;
    
                    @media screen and (max-width: $verySmall) {
                        font-size: 17vw;
                        line-height: 1.2;
                    }
                    
                }
            }

            
        }
    }
}

//small Tablets
@media screen and (max-width: $verySmall){
    #home-hero .hero__inner-wrapper .hero__inner-content .line {
        height: 100px;
        overflow: hidden;
    }
}


@media screen and (min-width: $verySmall) and (max-width: $small){
    #home-hero .hero__inner-wrapper .hero__inner-content .line h1{
        font-size: 90px;
    } 
    
    #home-hero .hero__inner-wrapper .hero__inner-content .line{
        height: 120px;
    }
}

//large tablets

@media screen and (min-width: $small) and (max-width: $medium){
    #home-hero .hero__inner-wrapper .hero__inner-content .line h1{
        font-size: 140px;
    }

    #home-hero .hero__inner-wrapper .hero__inner-content .line{
        height: 160px;
    }

    
}

@media screen and (min-width: $medium) and (max-width: $large){
    #home-hero .hero__inner-wrapper .hero__inner-content .line h1{
        font-size: 160px;
    }

    #home-hero .hero__inner-wrapper .hero__inner-content .line{
        height: 190px;
    }
}

@media screen and (min-width: $large) and (max-width: $large-max){
    #home-hero .hero__inner-wrapper .hero__inner-content .line h1{
        font-size: 180px;
    }

    #home-hero .hero__inner-wrapper .hero__inner-content .line{
        height: 210px;
    }

    
}

@media screen and (min-width: $large-max) {
    #home-hero .hero__inner-wrapper .hero__inner-content .line h1{
        font-size: 180px;
    }

    #home-hero .hero__inner-wrapper .hero__inner-content .line{
        height: 210px;
    }
}

