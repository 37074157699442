$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


.projects-wrapper{
    padding-bottom: 130px;
    padding-left: 40px;
    padding-right: 40px;
}

@media screen and (max-width: $verySmall){
    .projects-wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    .projects-wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (min-width: $small) and (max-width: $medium){
    .projects-wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }
}