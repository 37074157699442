
a{
    text-decoration: none!important;
}

body{
    overflow-x: hidden;
}

#scroller{
    height: 100vh;
    
}


h1,h2,h3,h4{
    margin-bottom: 0px!important;
}

#services{
    opacity: 0;
    overflow-x: hidden;
}

#home{
    opacity: 0;
    overflow-x: hidden;
}

#projects{
    opacity: 0;
    overflow-x: hidden;
}

#contact{
    opacity: 0;
    overflow-x: hidden;
}

#about{
    opacity: 0;
    overflow-x: hidden;
}

#single-project{
    
    overflow-x: hidden;
}

#blogs{
    overflow-x: hidden;
}

#single-blog{
    overflow-x: hidden;
}