$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


.blogs-main{
    .HB-wrapper{
        padding-left: 40px;
        padding-right: 40px;
        
        
        .HB-title{
            padding-bottom: 100px;
    
            .line{
                overflow: hidden;
                height: 78px;
            }
            h1{
                font-size: 62px;
                color: #1F1E1D;
                line-height: 78px;
                font-weight: 700;
            }
    
            p{
                font-size: 20px;
                line-height: 36px;
                color: #1F1E1D;
            }
        }
    
        .HB-main{
            .HB-main__inner{
                width: 100%;
                padding-bottom: 200px;
    
                
                .HB-inner__img{
                    width: 90%;
                    background-color: #ececec;
                    position: relative;
                    overflow: hidden;
                    height: 350px;
                    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    transition: all 0.4s ease;
    
                    &:hover{
                        transition: all 0.4s ease;
                        clip-path: polygon(5% 5%,100% 0%,100% 100%,5% 95%);
    
                        img{
                            transform: scale(1.05);
                            transition: all 0.8s ease;
                        }
                    }
                    img{
                        position: absolute;
                        top: 0%;
                        left: 0%;
                        width: 100%;
                        height: 100%;
                        transition: transform 0.8s ease;
                        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
                    }
                }
    
                .HB-main__content{
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 10px;
                    overflow: hidden;

                    h2{
                        color: #000;
                    }

                    p{ 
                        color: #000;
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width: $verySmall){

    .blogs-main .HB-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        // padding-top: 60px;
    }

    .blogs-main .HB-wrapper .HB-title .line{
        height: 48px;
    }
    .blogs-main .HB-wrapper .HB-title h1{
        font-size: 38px;
        line-height: 48px;
        margin: 0px!important;
    }

    .blogs-main .HB-wrapper .HB-title{
        padding-bottom: 30px;
    }

    .blogs-main .HB-wrapper .HB-title p{
        font-size: 16px;
        line-height: 26px;
    }

    .blogs-main .HB-wrapper .HB-main .HB-main__inner .HB-inner__img{
        width: 100%;
    }

    .blogs-main .HB-wrapper .HB-main .HB-main__inner{
        padding-bottom: 60px;
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    .blogs-main .HB-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
    }

    .blogs-main .HB-wrapper .HB-title .line{
        height: 48px;
    }
    
    .blogs-main .HB-wrapper .HB-title .line h1{
        font-size: 38px;
        line-height: 48px;
        margin: 0px!important;
    }

    .blogs-main .HB-wrapper .HB-title{
        padding-bottom: 30px;
    }

    .blogs-main .HB-wrapper .HB-title p{
        font-size: 16px;
        line-height: 26px;
    }

    .blogs-main .HB-wrapper .HB-main .HB-main__inner .HB-inner__img{
        width: 100%;
    }

    .blogs-main .HB-wrapper .HB-main .HB-main__inner{
        padding-bottom: 60px;
    }
}

@media screen and (min-width: $small) and (max-width: $medium){
    .blogs-main .HB-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
    }
   
    .blogs-main .HB-wrapper .HB-title{
        padding-bottom: 30px;
    }


    .blogs-main .HB-wrapper .HB-main .HB-main__inner .HB-inner__img{
        width: 90%;
    }

    .blogs-main .HB-wrapper .HB-main .HB-main__inner{
        padding-bottom: 60px;
    }
}

@media screen and (min-width: $medium) and (max-width: $large){
    .blogs-main .HB-wrapper{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 60px;
    }
   
    .blogs-main .HB-wrapper .HB-title{
        padding-bottom: 60px;
    }


    .blogs-main .HB-wrapper .HB-main .HB-main__inner .HB-inner__img{
        width: 90%;
    }

    .blogs-main .HB-wrapper .HB-main .HB-main__inner{
        padding-bottom: 60px;
    }
}