$verySmall: 480px;
$small: 768px;
$medium: 990px;
$large: 1200px;
$extraLarge: 1912px;


#footer{
    width:100%;
    background: #1F1E1D;
    height: 700px;
    display: flex;
    align-items: center;
    .footer-wrapper{
        padding-left: 40px;
        padding-right: 40px;
    }

    .footer-company{
        display: flex;
        flex-direction: column;
        position: relative;
        
        height: 320px;
        

        .footer-logo{
            margin-bottom: 40px;
            width: 150px;
            svg {
                path {
                    fill: #fff
                }
            }
        }

        .footer-social{
            display: flex;
            position: absolute;
            bottom: 0%;

            span{
                margin-right: 30px;
                svg{
                    stroke: #fff;
                }
            }

            .pint{
                svg{
                    fill: #fff;
                }
            }
        }

        h1, p{
            color: white;
        }

        p{
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            max-width: 240px;
        }
    }

    .footer-meet{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        height: 320px;
        
        .get-in-touch{
            
            position: absolute;
            top: 0%;
            left: 0%;
            .git-title{
                p{
                    color: white;
                    font-size: 14px;
                    font-weight: 900;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
            }

            .git-content{
                padding-top: 30px;
                p{
                    color: #DA2F46;
                    text-decoration: underline;
                    margin-bottom: 15px!important;
                    font-weight: 700;
                    letter-spacing: 2px;
                }
            }
        }

        .explore{

            position: absolute;
            bottom: 0;
            left: 0;

            .explore-links {
                ul{
                    display: flex;
                    margin-bottom: 15px!important;
                    padding-left: 0px!important;
                    li{
                        list-style: none;
                        padding-right: 10px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }

            .explore-title{
                padding-bottom: 30px;
                p{
                    color: white;
                    font-size: 14px;
                    font-weight: 900;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
            }

            .explore-content{
                p{
                    color: rgba(255,255,255,0.6);
                    font-weight: 600; 
                    font-size: 16px;               }
            }
        }
    }
}

@media screen and (max-width: $verySmall){

    #footer .footer-company p{
        font-size: 16px;
        line-height: 30px;
    }

    #footer .footer-company{
        height: 230px;
    }

    #footer .footer-wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    #footer .footer-meet{
        margin-top: 60px
    }
}

@media screen and (min-width: $verySmall) and (max-width: $small){
    #footer .footer-company{
        height: 230px;
    }

    #footer .footer-wrapper{
        padding-left: 10px;
        padding-right: 10px;
    }

    #footer .footer-meet{
        margin-top: 60px
    }
}